export const buildDateFilters = (): { start: number, end: number } => {
  const twoWeeksOffset = (24 * 60 * 60 * 1000) * 14

  const start = new Date()
  start.setTime(start.getTime() - twoWeeksOffset)
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)

  const startTimestamp = start.valueOf() / 1000

  const end = new Date()
  end.setDate(new Date().getDate())
  end.setHours(23)
  end.setMinutes(59)
  end.setSeconds(59)
  end.setMilliseconds(999)

  const endTimestamp = end.valueOf() / 1000

  return {
    start: Math.round(startTimestamp),
    end: Math.round(endTimestamp),
  }
}

export const getRandomInteger = (max: number): number => Math.floor(Math.random() * (max - 1))

import { api } from '@/main'
import { StepStepType } from '@/api/procedures'

export interface NormalisedSteps {
  status?: StepStepType,
  name?: string,
  numberMacro?: number,
}

export const getProcedureMacroSteps = async (procedureId: string): Promise<NormalisedSteps[]> => {
  const { data: procedure } = await api.procedures.proceduresGetProcedure({
    procedure: procedureId,
  })

  const { steps } = procedure
  if (!steps) return []

  const macroStepsList = steps.filter(step => step.isMacro && (step.numberMacro as number) > 0)
  const macroSteps = macroStepsList.map(step => ({
    status: step.type,
    name: `Macro Step ${step.numberMacro}`,
    numberMacro: step.numberMacro,
  }))

  return macroSteps
}

import { api } from '@/main'
import { defineStore } from 'pinia'
import { AiDevResolution, DevResolutionResolutionStatus } from '@/api/dev_resolution'
import { buildDateFilters, getRandomInteger } from './audits.helpers'
import { useUsersStore } from '@/stores/users/users'
import { AiProcedure } from '@/api/procedures'
import { filter } from 'lodash'

interface AuditsStoreState {
  procedureId: string,
  procedure?: AiProcedure,

  audits: AiDevResolution[],

  isAuditBeingUpdated: boolean,
  areAuditsLoading: boolean,
}

const MINIMUM_AMOUNT = 20

export const useAuditsStore = defineStore('auditsStore', {
  state: (): AuditsStoreState => ({
    procedureId: '',
    procedure: undefined,

    isAuditBeingUpdated: false,
    areAuditsLoading: true,

    audits: [],
  }),

  getters: {
    noAuditsAvailable: (state) => {
      const newAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Unknown)
      const inProgressAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Active)

      return !state.areAuditsLoading && !newAudits.length && !inProgressAudits.length
    },

    newAudits: (state) => state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Unknown),

    inProgressAudits(state) {
      const { userId } = useUsersStore()
      return state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Active && audit.auditor === `auditors/${userId}`)
    },

    completeAudits: (state) => state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Complete),

    areNewAuditsAvailable: (state) => {
      const newAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Unknown)
      return !state.areAuditsLoading && newAudits.length
    },

    newAuditsLeft: (state) => {
      const audits = state.audits
      const inProgressAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Active && audit.auditor !== '')
      const completeAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Complete && audit.auditSubmissionTime !== null)
      const amountLeft = audits.length - inProgressAudits.length - completeAudits.length

      if (amountLeft > MINIMUM_AMOUNT) return MINIMUM_AMOUNT
      return amountLeft
    },

    areInProgressAuditsAvailable: (state) => {
      const { userId } = useUsersStore()
      const inProgressAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Active && audit.auditor === `auditors/${userId}`)
      return !state.areAuditsLoading && inProgressAudits.length
    },

    inProgressAuditsLeft: (state) => {
      const { userId } = useUsersStore()
      const inProgressAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Active && audit.auditor === `auditors/${userId}`)

      return inProgressAudits.length
    },

    areCompleteAuditsAvailable: (state) => {
      const completeAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Complete)
      return !state.areAuditsLoading && completeAudits.length
    },

    auditingProgress: (state) => {
      if (state.areAuditsLoading) return 'Calculating'
      const completeAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Complete)
      return `${completeAudits.length}/${state.audits.length}`
    },

    nextNewAuditUrl: (state) => {
      const newAuditsList = state.audits.filter(audit =>
        audit.resolutionStatus === DevResolutionResolutionStatus.Unknown &&
        audit.auditSubmissionTime === null &&
        audit.auditor === '',
      )

      const randomInteger = getRandomInteger(newAuditsList.length)
      const randomAudit = newAuditsList[randomInteger]

      if (!randomAudit) return ''

      const [, sessionId,, unitId] = (randomAudit.unit as string).split('/')
      const [, devResolutionId] = (randomAudit.name as string).split('/')

      return `/procedure-library/${state.procedureId}/audit/${devResolutionId}?session=${sessionId}&unit=${unitId}`
    },

    nextInProgressAuditUrl: (state) => {
      const { userId } = useUsersStore()
      const completeAudits = state.audits.filter(audit => audit.resolutionStatus === DevResolutionResolutionStatus.Active && audit.auditor === `auditors/${userId}`)

      if (!completeAudits.length) return ''

      const randomInteger = getRandomInteger(completeAudits.length)
      const randomInProgressAudit = completeAudits[randomInteger]
      const [, sessionId,, unitId] = (randomInProgressAudit.unit as string).split('/')
      const [, devResolutionId] = (randomInProgressAudit.name as string).split('/')

      return `/procedure-library/${state.procedureId}/audit/${devResolutionId}?session=${sessionId}&unit=${unitId}`
    },
  },

  actions: {
    async setProcedure(payload: string) {
      this.procedureId = payload
      await this.fetchProcedure(payload)
    },

    async fetchProcedure(procedureId: string) {
      const { userGroup } = useUsersStore()
      if (!userGroup) return

      try {
        const { data: procedure } = await api.procedures.proceduresGetProcedure({
          procedure: procedureId,
        }, { params: { filter: { manufacturer: { $in: userGroup } } } })

        this.procedure = procedure as AiProcedure
      } catch (error) {
        console.error(error)
      }
    },

    async completeDevResolution(devResolutionId: string, audit: AiDevResolution) {
      this.isAuditBeingUpdated = true

      try {
        await api.devResolutions.devResolutionsUpdateDevResolution({
          devresolution: devResolutionId,
          body: {
            auditor: audit.auditor,
            multipleUnits: audit.multipleUnits,
            earlyStart: audit.earlyStart,
            earlyStop: audit.earlyStop,
            additionalNote: audit.additionalNote,
            manualSelections: audit.manualSelections,
            steps: audit.steps,
            auditSubmissionTime: new Date().toISOString(),
            resolutionStatus: DevResolutionResolutionStatus.Complete,
          },
        })

        return true
      } catch (error) {
        console.error(error)
        return false
      } finally {
        this.isAuditBeingUpdated = false
      }
    },

    async markDevResolutionAsInProgress(userId: string, devResolutionId: string) {
      if (!userId) return

      try {
        const { data: devResolution } = await api.devResolutions.devResolutionsUpdateDevResolution({
          devresolution: devResolutionId,
          body: {
            auditor: `auditors/${userId}`,
            resolutionStatus: DevResolutionResolutionStatus.Active,
            auditStartTime: new Date().toISOString(),
          },
        })

        return devResolution as AiDevResolution
      } catch (error) {
        console.error(error)
        return {}
      }
    },

    async fetchAudits() {
      this.areAuditsLoading = true

      const { userGroup } = useUsersStore()
      if (!userGroup) return

      try {
        const { start, end } = buildDateFilters()
        const { data: { devResolutions } } = await api.devResolutions.devResolutionsListDevResolutions({
          pageSize: -1,
          filter: JSON.stringify({
            $and: [{
              procedure: `procedures/${this.procedureId}`,
            }, {
              'created.seconds': { $gte: start },
            }, {
              'created.seconds': { $lte: end },
            }],
          }),
        })

        this.audits = devResolutions as AiDevResolution[]
      } catch (error) {
        console.error(error)
        this.audits = []
      } finally {
        this.areAuditsLoading = false
      }
    },
  },
})
